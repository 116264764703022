import React, { useContext, useEffect, useState, useCallback } from "react"
import { StyleSheet, Text, View, TouchableOpacity, Clipboard, Image, Dimensions } from "react-native"
import { GlobalContext } from "../../services/GlobalContext"
import MainHeader from "../DonatePage/components/MainHeader"
import Section from "./components/Section"
import { useNavigation } from "@react-navigation/native"
import BackButton from "./components/BackButton"
import axios from "axios"

 export default function PaymentPage() {
    const { orderNumber, valor, paymentType, orderId, qrCode64 } = useContext(GlobalContext)
    const {navigate} = useNavigation()
    const [timeLeft, setTimeLeft] = useState(300)
    const [getTimer, setGetTimer] = useState(5)
    const [status, setStatus] = useState()
    const [qrCodeUrl, setQrCodeUrl] = useState('')

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
      const updateLayout = () => {
        const screenWidth = Dimensions.get("window").width;
        setIsSmallScreen(screenWidth < 768)
      };
  
      updateLayout();
  
      const subscription = Dimensions.addEventListener("change", updateLayout);
  
      return () => {
        subscription?.remove();
      };
    }, []);
  
    const getOrderData = useCallback(async () => {
        try {
          const {data} = await axios.get(`https://api-totem.pacsafe.com.br/api/checkout/${orderId}`)
          setStatus(data.order.status)
        } catch (error) {
          console.error(error)
        }
    }, [orderId])
      
    const generateQRCode = useCallback(() => {
        if (qrCode64) {
          const url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(qrCode64)}`
          setQrCodeUrl(url)
        }
    }, [qrCode64])

    useEffect(() => {
        getOrderData()
    }, [getOrderData])

    useEffect(() => {
        console.log(qrCode64)
        generateQRCode()
    }, [qrCode64, generateQRCode])

    useEffect(() => {
        console.log(qrCodeUrl)
    }, [qrCodeUrl])

    useEffect(() => {
        if(timeLeft > 0) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1)
            }, 1000)

            return () => clearInterval(intervalId)
        }

        if(timeLeft === 0){
            navigate('Home')
        }
    }, [timeLeft, navigate])

    useEffect(() => {
        if(getTimer > 0 ){
            const intervalId = setInterval(() => {
                setGetTimer((prev) => prev -1)

            }, 1000)
            return () => clearInterval(intervalId)
        }

        if(getTimer === 0){
            getOrderData()
            setGetTimer(5)
        }

    }, [getTimer, getOrderData])

    useEffect(() => {
        if(status === 'concluido'){
            console.log('pedido concluido')
            navigate('ConfirmPage')
        }
    }, [status, navigate])

    function formatCurrency (value) {
        return `R$ ${value.replace('.' , ',')}`
    }

    function formatTime (seconds) {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
    }
    
    function copyToClipBoard(){
        Clipboard.setString(qrCode64)
    }

    return (
         <View 
         style={styles.mainContainer}
         > 

             <View>
                 <MainHeader showButtons={false} />
             </View>
             <View style={styles.bodyContainer}>
                 <View style={{width: isSmallScreen ? '80%' :'50%'}}>
                     <Section isSmallScreen={isSmallScreen} label="Número da contribuição:" value={orderNumber ? orderNumber : '---'} />
                     <Section isSmallScreen={isSmallScreen} label="Valor da contribuição:" value={formatCurrency(valor) ? formatCurrency(valor) : '---'} />
                     <Section isSmallScreen={isSmallScreen} label="Forma da contribuição:" value={paymentType ? paymentType : '---'} />
                     <Section isSmallScreen={isSmallScreen} label="O codigo expira em:" value={`${formatTime(timeLeft) ? formatTime(timeLeft) : '---'}`}/>
                 </View> 
                 {qrCode64 ? (
                     <View style={styles.qrCodeContainer}>
                         <Text 
                         style={{color:"#000", fontWeight:"bold", marginBottom:10, fontSize: isSmallScreen ? 14 : 18}}>
                             Escaneie o código QR do PIX para pagamento.
                         </Text>  
                         {qrCodeUrl ? (
                            <>
                                <Image style={styles.qrCode} source={{ uri: qrCodeUrl }} />
                            </>
                            ) : (
                              <Text style={styles.placeholder}>O QRCode está sendo gerado.</Text>
                        )}
                         <TouchableOpacity 
                         onPress={copyToClipBoard}
                         style={styles.clipBoard}>
                             <Text style={styles.qrCodeText} numberOfLines={1} ellipsizeMode="tail">
                                 {qrCode64}
                             </Text>
                             <View>
                                 <Text style={styles.copyText}>Copiar</Text>
                             </View>
                         </TouchableOpacity>              
                     </View> 
                 ) : 
                  (
                     <View style={styles.qrCodeContainer}>
                         <Text style={{color:"#000", fontWeight:"bold", marginBottom:10}}>Aperte o Botão verde e inicie o pagamento.</Text>
                         <View style={styles.cardContainer}>
                             <View style={styles.imageCard}>
                             <Image
                                 style={styles.imageContainer}
                                 source={{ uri: 'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/Tasa0_desktop--c4823e18.png' }} 
                             />
                             </View>
                             <View style={{margin:10}}>
                                 <View style={{flexDirection:'row', alignItems:'center'}}>
                                     <Text style={styles.copyText2}>1)Na MAQUINETA APERTE o Botão verde</Text>
                                     <View style={styles.button}>
                                     </View>
                                 </View>
                                 <Text style={styles.copyText2}>2) APROXIME ou INSIRA seu cartão</Text>
                                 <Text style={styles.copyText2}>3) AGUARDE a confirmação do pagamento</Text>
                             </View>
                         </View>
                     </View>
                 )}
                 <View style={styles.buttonContainer}>
                     <BackButton/>
                 </View>  
            </View>          
        </View>

)}

 const styles = StyleSheet.create({
     mainContainer:{
         flex:1,
         justifyContent:'space-between',
         width:'100%',
     },
     bodyContainer:{
         alignItems:'center',
         justifyContent:'center',
         width:'100%',
         flex: 1,
         paddingTop: 20,
     },
     qrCode: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    
     qrCodeContainer:{
         marginTop:20,
         alignItems:'center',        
     },
     buttonContainer:{
         marginTop:0,
         paddingBottom:40,
         width:'40%'
     },
     clipBoard: {
         flexDirection: 'row',
         alignItems: 'center',
         justifyContent: 'space-between',
         padding: 10,
         margin:20,
         marginBottom: 40,
         borderWidth: 1,  
         borderColor: '#ccc',  
         borderRadius: 5
     },
     qrCodeText: {
         color: "#000",
         width: 200,
         overflow: 'hidden', 
     },
     copyText: {
         color: "#000",
         fontWeight: 'bold', 
     },
     copyText2: {
         color: "#000",
         fontWeight: 'bold',
         textAlign:'left', 
     },
     imageCard:{
         alignItems:'center',
         justifyContent:'center',
         flex:1,
         margin:10,
        //  shadowColor: '#000',       
        //  shadowOffset: { width: 0, height: 2 },  
        //  shadowOpacity: 0.25,        
        //  shadowRadius: 3.84,   
     },
     imageContainer:{
        width: 150,
        height: 150,
        resizeMode: 'contain',
     },
     button:{
         backgroundColor: '#0dfd21',
         padding: 10,
         borderRadius:8,
         elevation:1,
         justifyContent:'center',
         alignItems:'center',
         width:40,
         margin:4,
     },
     cardContainer:{
         backgroundColor:'#fff', 
         borderColor:'#000', 
         padding: 4, 
         elevation:3, 
         margin: 10,
         justifyContent:'space-evenly', 
         borderRadius: 8, 
         shadowColor: '#000',         
         shadowOffset: { width: 0, height: 2 }, 
         shadowOpacity: 0.25,        
         shadowRadius: 3.84,   
     }
 })


import { View, StyleSheet, ScrollView, Dimensions, Image, Modal, Text, TouchableOpacity, TouchableWithoutFeedback, ImageBackground } from 'react-native'
import NavigateButton from './components/NavigateButton'
import TitleSection from './components/TitleSection'
import ClockFooter from './components/ClockFooter'
import DateFooter from './components/DataFooter'
import { useState, useEffect, useContext, useCallback, useRef } from "react"
import axios from 'axios'
import { GlobalContext } from '../../services/GlobalContext'

export default function HomePage() {

    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const {setSettings, settings} = useContext(GlobalContext)
    const [showModal, setShowModal] = useState(false)
    const [personName, setPersonName] = useState('')
    const [facialImageUri, setFacialImageUri] = useState('')
    const showModalRef = useRef(showModal);



    async function getSettings () {
        try {
            const {data} = await axios.get('https://api-totem.pacsafe.com.br/api/general-settings')
            console.log(data)
            return data            
        } catch (error) {
            console.error(error)
        }
    }

    const sendBase64Image = useCallback((async (base64String, name, serviceUrl) => {

      try {
          // Decodificar Base64 sem prefixo
          const byteCharacters = atob(base64String);
          const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
  
          // Criar um Blob e um arquivo a partir do Base64
          const blob = new Blob([byteArray], { type: 'image/png' }); // Ajuste o MIME type se necessário
          const file = new File([blob], "image.png", { type: 'image/png' });
  
          // Criar o FormData com os campos 'nome' e 'imagem'
          const formData = new FormData();
          formData.append('nome', name);
          formData.append('imagem', file);
  
          // Enviar a requisição
          const response = await fetch(serviceUrl, {
              method: 'POST',
              body: formData,
          });
  
          if (!response.ok) {
              throw new Error(`Erro ao enviar dados: ${response.statusText}`);
          }
  
          return response.json(); // Retorno esperado
      } catch (error) {
          console.error("Erro ao processar ou enviar os dados:", error);
          throw error;
      } 
    }), [])

    useEffect(() => {
      showModalRef.current = showModal;
    }, [showModal]);

    const openModal = useCallback((personName, image) => {
      setPersonName(personName);
      setFacialImageUri(image);
      setShowModal(true);
    }, []);

    const getEvents = useCallback(async () => {
      try {
        const url = "https://df76-168-195-215-218.ngrok-free.app/stream-data";

        // Configura os headers
        const headers = new Headers({
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420", // Header necessário
        });
    
        // Faz a requisição fetch com headers
        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });

        if (!response.body) {
          console.error('Stream não encontrado.');
          return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        const processStream = async () => {
          let buffer = '';
          let name = '';
          let image = '';

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              console.log('Stream encerrado.');
              break;
            }

            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;
            const events = buffer.split('\n');
            buffer = events.pop();

            for (const event of events) {
              try {
                const parsedEvent = JSON.parse(event);

                if (parsedEvent.personName) {
                  name = parsedEvent.personName.split('Events')[0];
                }

                if (parsedEvent.base64Image) {
                  image = parsedEvent.base64Image;
                }
              } catch (err) {
                console.error('Erro ao analisar JSON:', err.message, event);
              }
            }

            if (buffer.trim()) {
              try {
                const parsedEvent = JSON.parse(buffer);
                if (parsedEvent.base64Image) {
                  image = parsedEvent.base64Image;
                }
                if (parsedEvent.personName) {
                  name = parsedEvent.personName.split('Events')[0];
                }
              } catch (err) {
                console.error('Erro ao analisar JSON do buffer final:', err.message);
              }
            }

            // Certifique-se de usar a ref para acessar o estado atual
            if (name && image && !showModalRef.current) {
              await sendBase64Image(
                image,
                name,
                'https://api-totem.pacsafe.com.br/api/face-events'
              );
              openModal(name, image);
            }
          }
        };

         await processStream();
       } catch (error) {
         console.error('Erro ao conectar ao servidor:', error);
       }
    } , [sendBase64Image, openModal]);
    

    useEffect(() => {
        
        const getData = async () => {
            const data = await getSettings()
            setSettings(data)
        }

        getData()

    }, [setSettings])

    useEffect(() => {
      const updateLayout = () => {
        const screenWidth = Dimensions.get("window").width
        setIsSmallScreen(screenWidth < 768)
      }
  
      updateLayout()
  
      const subscription = Dimensions.addEventListener("change", updateLayout)
  
 
      return () => {
        subscription?.remove()
      }
    }, [])

    const closeModal = useCallback(() => {
        setShowModal(false)
        setPersonName('')
    }, [])  
    
    useEffect(() => {
      getEvents()
    }, [getEvents])

    useEffect(() => {
      console.log(personName)
      console.log(facialImageUri)
      if(personName && facialImageUri){
        setShowModal(true)
      }
    }, [personName, facialImageUri])

    useEffect(() => {
      let timer;
  
      if (showModal) {
        // Inicia um timer de 30 segundos
        timer = setTimeout(() => {
          closeModal();
        }, 30000); // 30000ms = 30s
      }
  
      // Limpa o timer caso o modal seja fechado antes dos 30 segundos
      return () => clearTimeout(timer);
    }, [showModal, closeModal]);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
    
        },
        scrollViewContainer: {
            flexGrow: 1, 
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 20,

        },
        mainContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
          sectionContainer: {
            width:'60%',
            margin: isSmallScreen ? 0 : 40,
          },
          sectionWrapper: {
            flexDirection: isSmallScreen ? 'column' : "row",
            alignItems: isSmallScreen ? 'flex-start' :'center',
            justifyContent:'center'
          },
          clockFooterContainer: {
            flexDirection:'row',
            position: 'absolute', 
            bottom:1,
            width: '100%', 
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            backgroundColor: 'transparent', 
            zIndex: 4, 
        },
        backgroundImage: {
            flex: 1,
            resizeMode: 'cover',
        },

          modalOverlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
          },
          modalContent: {
            width: isSmallScreen ? 300 : 600,
            height: isSmallScreen ? 300 : 500,
            paddingTop: 20,
            paddingBottom: 30,
            backgroundColor: '#fff',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent:'space-between',
            elevation: 5,
          },
          modalTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 10,
          },
          modalText: {
            fontSize: isSmallScreen ? 16 : 20,
            marginBottom: 20,
          },
    })

    return (
        <>
            <View>
              <Modal
                animationType="fade"
                transparent={true}
                visible={showModal}
                onRequestClose={closeModal}
              >
                {/* Detecta cliques fora do conteúdo do modal */}
                <TouchableWithoutFeedback onPress={closeModal}>
                  <View style={styles.modalOverlay}>
                    {/* Evita que cliques no conteúdo fechem o modal */}
        <TouchableWithoutFeedback onPress={() => {}}>
          <View style={styles.modalContent}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderBottomWidth: 1,
                borderBottomColor: "#d9d9d9",
                width: "100%",
              }}
            >
              <Text style={styles.modalTitle}>Seja bem vindo!</Text>
            </View>

            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderBottomWidth: 1,
                borderBottomColor: "#d9d9d9",
                width: "100%",
              }}
            >
              <Image
                style={{
                  width: isSmallScreen ? 100 : 200,
                  height: isSmallScreen ? 100 : 300,
                  borderRadius: 20,
                  margin: 10,
                  resizeMode: "contain",
                }}
                source={{ uri: `data:image/png;base64,${facialImageUri}` }}
              />
              <Text style={styles.modalText}>{personName}</Text>
            </View>

            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                paddingTop: 10,
              }}
            >
                          <TouchableOpacity
                            onPress={closeModal}
                            style={{
                              backgroundColor: "#1A83E3",
                              padding: 14,
                              borderRadius: 8,
                              alignItems: "center",
                              justifyContent: "center",
                              elevation: 5,
                              width: isSmallScreen ? "60%" : "30%",
                              height: 40,
                            }}
                          >
                            <Text style={{ color: "#fff" }}>Fechar</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </TouchableWithoutFeedback>
              </Modal>
            </View>
            {settings && (
                        <ImageBackground
                        style={styles.backgroundImage}
                        source={{uri:settings.settings[0].more_configs.plano_de_fundo}}>
                                        <View style={styles.container}>
                                <TitleSection/>
                                <ScrollView contentContainerStyle={styles.scrollViewContainer}>
                                   <View style={styles.sectionWrapper}>
                                        <View style={styles.sectionContainer}>
                                            <NavigateButton
                                            text={'Sua oferta é um ato de amor e de compromisso com a obra de Deus e com a igreja do Senhor. '}
                                            icon={
                                            <Image
                                            style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/4689/4689030.png'}}/>
                                            }
                                            label='Contribua'
                                            value='Donate'
                                            isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Conheça um pouco mais sobre os projetos que a igreja está envolvida e faça parte da mudança!'}
                                            icon={
                                            <Image
                                            style={{width: isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/11677/11677498.png'}}/>
                                            }
                                            label='Eventos'
                                            value='CalendarPage'
                                            isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Acompanhe nossas últimas novidades sobre eventos, artigos e notícias.'}
                                            icon={
                                            <Image
                                            style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/78/78875.png'}}/>
                                            }
                                            label='Notícias'
                                            value='NewsPage'
                                            isSmallScreen={isSmallScreen}
                                            />
                                        </View>
                                        <View style={styles.sectionContainer}>
                                            <NavigateButton
                                            text={'Conheça um pouco mais sobre a nossa programação.'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/1946/1946429.png'}}/>
                                                }
                                                label='Conecte-se'
                                                // fontSize={14}
                                                value='RegisterPage'
                                                isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                                text={'Preservamos o cuidado mútuo entre nossa instituição e a nossa comunidade!'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/8644/8644718.png'}}/>
                                                }
                                                label='Sobre nós'
                                                value='About'
                                                // fontSize={14}
                                                isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Explore a Bíblia como uma fonte de luz e orientação espiritual.'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/1511/1511384.png'}}/>
                                                }
                                                label='Biblia'
                                                value='Biblia'
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </View>
                                    </View>
                                    <View>
                                        {/* <Video
                                        source={require('../../assets/video.mp4')}
                                        /> */}
                                    </View>
                                </ScrollView>
                                {!isSmallScreen && (
                                    <View style={styles.clockFooterContainer}>
                                        <DateFooter/>
                                        <ClockFooter/>
                                    </View>
                                )}
                
                            </View>
                        </ImageBackground>
            )}
        </>


    )
}

